import React from "react"
import { Helmet } from "react-helmet"
import config from "../../site.config"

/** components */
import Layout from "../components/Layout"
import { theme } from "../components/Theme"

/** dynamic sections */
import HeroTopSection from "../components/dynamic-sections/HeroTopSection"
import RibbonSection from "../components/dynamic-sections/RibbonSection"
import HeroRightSection from "../components/dynamic-sections/HeroRightSection"
import FourImageTemplate from "../components/dynamic-sections/FourImageTemplate"
import FourImageSmallSection from "../components/dynamic-sections/FourImageSmallSection"
import AwardsSection from "../components/dynamic-sections/AwardsSection"
import TwoBoxSection from "../components/dynamic-sections/TwoBoxSection"
import GalleryImageSection from "../components/dynamic-sections/GalleryImageSection"

/** color theme */
const colorPalette = theme.colorPalettes.blue

/** svg */
import AppStoreIcon from "../../assets/appstore-icon.svg"
import NorhartIcon from "../../assets/norhart-icon.svg"
import NorhartDarkCircleIcon from "../../assets/norhart-dark-circle-icon.svg"

/** props */
interface Props {
  data: any
}

/** const */
const TechnologyPage: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/technology/",
          name: "Technology | Norhart",
          image: `${config.siteMetadata.siteUrl}/technology/technology-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Technology | Norhart"
      description="Smart innovative luxury apartments in Forest Lake, Blaine and, Oakdale MN. Use the Norhart Home app with your Apple iPhone and Watch to manage your Norhart apartment and life."
      keywords="norhart, luxury, innovative, smart, technology, apartment, apartments, building, buildings, mn, minnesota, minneapolis, st paul, rent, rentals, resident, residents, forest lake, blaine, circle pines, lexington, coon rapids, Oakdale, encore apartments, lexington lofts apartments, Oakdale apartments, gateway green apartments, mill pond apartments, birchview apartments, legacy pointe apartments, heritage heights apartments, springwood apartments, greystone apartments, northbrook apartments, construction, norhart construction, lean construction"
      imageTwitter={`${config.siteMetadata.siteUrl}/technology/norhart-technology-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/technology/norhart-technology-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroTopSection
        title="Experience Smart Living"
        subtitle="Norhart Builds Smart Apartments"
        imageTitle="Norhart Smart Living"
        image="/technology/norhart-technology-hero.png"
        colorPalette={colorPalette}
      />

      <RibbonSection
        title="Smart Apartments"
        tagLine="Manage your apartment with your iPhone"
        colorPalette={colorPalette}
      />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <TwoBoxSection
        backgroundColorOne=""
        backgroundColorTwo=""
        boxAddressOne="9001 Griggs Ave "
        boxAddressTwo="1081 4th St. SW "
        boxAltOne="Norhart Lexington Loft Apartments in Blaine"
        boxAltTwo="Norhart Encore Apartments in Forest Lake"
        boxCityOne="Lexington, MN 55014"
        boxCityTwo="Forest Lake, MN 55025"
        boxImgOne="/apartments/norhart-lexington-lofts-apartments-blaine.webp"
        boxImgTwo="/apartments/norhart-encore-apartments-forest-lake.webp"
        boxPhoneOne="651-237-3322"
        boxPhoneTwo="651-243-3041"
        boxTitleOne="Lexington Loft Apartments "
        boxTitleTwo="Encore Apartments "
        boxURLOne="/blaine/lexington-lofts/"
        boxURLTwo="/forest-lake/encore-apartments/"
        colorPalette={colorPalette}
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Smart Living"
        subtitle="Our passion is building communities, enriching people's lives, and creating innovations to makes people's lives easier."
        title="Smart Apartments"
      />

      <FourImageTemplate
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        fontTextColor="#FFFFFF"
        icon={AppStoreIcon}
        iconTitle="Norhart iOS App"
        title="Sneek Peek Into The Future"
        iconTitle="Norhart Smart Home"
        subtitle="At Norhart, we are always looking for ways to improve people's lives. Our commitment to creating innovative smart apartments is just the start. We are hard at work to make a better way for you to live!"
        imageTitleOne="Watch Your Home"
        imageOneText="You're out running errands, and you left your iPhone in the car! Just turn your wrist and manage your Norhart apartment with your Apple Watch!"
        imageOne="/technology/norhart-technology-apple-watch.webp"
        imageOneAlt="Norhart Apple Watch App"
        imageOnePill="In Development"
        imageTitleTwo="Mission Control"
        imageTwoText="NASA has mission control, and now you have a mini version for your apartment. Easily manage your smart home in one place, Thanks Norhart!"
        imageTwo="/technology/norhart-technology-dashboard-apple-watch.webp"
        imageTwoAlt="Norhart Home App Manage Your Home"
        imageTwoPill="In Development"
        imageTitleThree="Set The Mood"
        imageThreeText="You're on the sofa, and you're ready to set the mood for that classic Star Wars movie. Adjust the lighting, create the atmosphere, and enjoy the night."
        imageThree="/technology/norhart-technology-adjust-lights.webp"
        imageThreeAlt="Norhart Home App Turn on Lights"
        imageThreePill="In Development"
        imageTitleFour="Climate Control"
        imageFourText="It's a cold Minnesota night and you're in bed. You don't want to get up to change the thermostat. Just use our app and be toasty in minutes."
        imageFour="/technology/norhart-technology-control-thermostat.webp"
        imageFourAlt="Norhart Home App Manage Thermostat"
        imageFourPill="In Development"
        pillActive="Active"
        colorPalette={colorPalette}
        imageTopPad={3}
      />

      <FourImageSmallSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        icon={NorhartDarkCircleIcon}
        title="Smart Apartments"
        subtitle="Use your Apple iOS devices: iPhone and Apple Watch to control and manage your Norhart Apartment. So Cool! This exclusive smart-home technology will be included for free with our Series 3 Apartments."
        imageOnePrice="Sneek Peek"
        imageHeaderOne="Technology"
        imageTitleOne="iPhone"
        imageSubTitleOne="Control your home wherever you are."
        imageOne="/technology/norhart-technology-iphone.webp"
        imageOneAlt="Norhart iPhone Home App"
        imageTwoPrice="Sneek Peek"
        imageHeaderTwo="Technology"
        imageTitleTwo="Apple Watch"
        imageSubTitleTwo="Manage your home with just a glance."
        imageTwo="/technology/norhart-technology-watch.webp"
        imageTwoAlt="Norhart Apple Watch App"
        imageThreePrice="Sneek Peek"
        imageHeaderThree="Technology"
        imageTitleThree="Hey Siri"
        imageSubTitleThree="Hey Siri... Turn off my bedroom lights."
        imageThree="/technology/norhart-technology-siri.webp"
        imageThreeAlt="Norhart Hey Siri"
        imageFourPrice="Sneek Peek"
        imageHeaderFour="Technology"
        imageTitleFour="Siri Shortcuts"
        imageSubTitleFour="Turn down the thermostat at dusk."
        imageFour="/technology/norhart-technology-shortcuts.webp"
        imageFourAlt="Norhart Siri Shortcuts"
        buttonUrl="/apartments/"
        buttonName="Learn More"
        priceActive={true}
        colorPalette={colorPalette}
        imageExtraOne="/technology/gallery/extras/extras-1.webp"
        imageTitleExtraOne="Norhart Extra Image 1"
        imageExtraTwo="/technology/gallery/extras/extras-2.webp"
        imageTitleExtraTwo="Norhart Extra Image 2"
        imageExtraThree="/technology/gallery/extras/extras-3.webp"
        imageTitleExtraThree="Norhart Extra Image 3"
        imageExtraFour="/technology/gallery/extras/extras-4.webp"
        imageTitleExtraFour="Norhart Extra Image 4"
        imageExtraFive="/technology/gallery/extras/extras-5.webp"
        imageTitleExtraFive="Norhart Extra Image 5"
        imageExtraSix="/technology/gallery/extras/extras-6.webp"
        imageTitleExtraSix="Norhart Extra Image 6"
        singleHeroImage={true}
        singleHeroImageExtraOne="/apartments/norhart-apartments-floor-plan.png"
        singleHeroImageExtraTitleOne="Norhart Smart Apartments"
        asoEffect="fade"
      />

      <GalleryImageSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={AppStoreIcon}
        iconTitle="Sneek Peek"
        title="Norhart Vision For The Future"
        subtitle="We are hard at work designing our next generation Smart Apartment iOS App (Native of course!) to allow our residents the option to manage their Series 3 apartment thermostat, lights, fans, and more!"
        imageTitleOne="Norhart iOS Home App Image 1"
        imageOne="/technology/gallery/norhart-home-app/norhart-ios-app-1.webp"
        imageTitleTwo="Norhart iOS Home App Image 2"
        imageTwo="/technology/gallery/norhart-home-app/norhart-ios-app-2.webp"
        imageTitleThree="Norhart iOS Home App Image 3"
        imageThree="/technology/gallery/norhart-home-app/norhart-ios-app-3.webp"
        imageTitleFour="Norhart iOS Home App Image 4"
        imageFour="/technology/gallery/norhart-home-app/norhart-ios-app-4.webp"
        imageTitleFive="Norhart iOS Home App Image 5"
        imageFive="/technology/gallery/norhart-home-app/norhart-ios-app-5.webp"
        imageTitleSix="Norhart iOS Home App Image 6"
        imageSix="/technology/gallery/norhart-home-app/norhart-ios-app-6.webp"
        colorPalette={colorPalette}
        badge={true}
      />

      <AwardsSection
        header="Norhart Smart Living"
        title="Norhart Innovation"
        subtitle="We invite you to explore our Forest Lake and Blaine, Minnesota smart apartments. Once you experience Norhart's smart living, believe me there is no going back!"
        colorPalette={colorPalette}
      />
    </Layout>
  )
}

/** export */
/** export */
export default TechnologyPage
