import React from "react"
import { ColorPalette } from "../Theme"

/** props */
interface Props {
  backgroundColorOne?: string
  backgroundColorTwo?: string
  boxAddressOne: string
  boxAddressTwo: string
  boxAltOne: string
  boxAltTwo: string
  boxCityOne: string
  boxCityTwo: string
  boxImgOne: string
  boxImgTwo: string
  boxPhoneOne: string
  boxPhoneTwo: string
  boxTitleOne: string
  boxTitleTwo: string
  boxURLOne: string
  boxURLTwo: string
  buttonName?: string
  buttonUrl?: string
  colorPalette: ColorPalette
  fontTextColor?: string
  icon: any
  iconTitle: string
  subtitle: string
  title: string
}

/** const */
const TwoBoxSection: React.FC<Props> = (props) => {
  const Icon = props.icon

  return (
    <section
      className="container-fluid sectionBaseColor pt-5 pb-5"
      style={{
        background: `linear-gradient(${props.backgroundColorOne} 30%, ${props.backgroundColorTwo} 70%)`,
        color: `${props.colorPalette.sectionText}`,
      }}
    >
      <div className="container pl-3 pr-3 text-center">
        <div className="container pb-5"></div>
        <div className="text-center pb3">
          <Icon />
        </div>
        <div
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl text-center"
          style={{ color: `${props.fontTextColor}`, fontSize: "15px" }}
        >
          {props.iconTitle}
        </div>
        <hr
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl style-line mt-3 mb-3"
          style={{ borderColor: "rgba(0, 0, 0, 0.2)", color: `${props.fontTextColor}` }}
        />
        <h3 className="contentHeroTitle" style={{ color: `${props.fontTextColor}` }}>
          {props.title}
        </h3>
        <h2 className="contentHeroSubTitle pb-4" style={{ color: `${props.fontTextColor}`, opacity: "0.7" }}>
          {props.subtitle}
        </h2>

        {props.buttonUrl && (
          <div className="d-flex justify-content-center mb-2">
            <a
              className="btn btn-md btn-light animate__animated animate__animated animate__pulse animate__infinite animate__slow"
              href={props.buttonUrl}
              target="_blank"
              style={{
                borderColor: "#fff",
                color: "#fff",
                border: "0px",
                backgroundColor: `${props.colorPalette.heroTop}`,
              }}
            >
              {props.buttonName}
            </a>
          </div>
        )}

        <div className="row mt-5 mb-5">
          <div className="col-sm-6">
            <div
              className="card card-site high p-0"
              style={{ borderRadius: "8px", border: "transparent", backgroundColor: "#FFFFFF", color: "#444444" }}
            >
              <div className="item">
                <img src={props.boxImgOne} loading="lazy" decoding="async" alt={props.boxAltOne} />
              </div>
              <div className="p-3">
                <h3 className="cardHeader-site pb-2" style={{ fontSize: "20px" }}>
                  <strong>{props.boxTitleOne}</strong> <br />
                  <span
                    className="badge badge-secondary m-3"
                    style={{ backgroundColor: `${props.colorPalette.buttonNew}` }}
                  >
                    Series 1 Apartments
                  </span>
                </h3>
                <h4
                  className="cardText-site p-0"
                  style={{ color: "#555555", opacity: "0.7", fontSize: "18px", lineHeight: "20px" }}
                >
                  {props.boxAddressOne} <br /> {props.boxCityOne}
                </h4>
                <div className="pb-2 pt-2">
                  <hr
                    className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl style-line mt-3 mb-3"
                    style={{ borderColor: "rgba(0, 0, 0, 0.2)" }}
                  />
                </div>
                <div className="pb-4">
                  <span style={{ fontSize: "18px" }}>
                    <a href="tel:651-237-3322">{props.boxPhoneOne}</a>
                  </span>
                </div>
                <a
                  className="btn btn-md"
                  href={props.boxURLOne}
                  target="_blank"
                  style={{ backgroundColor: `${props.colorPalette.heroTop}`, color: "#fff" }}
                >
                  <span>Explore Apartment</span>
                </a>
              </div>
            </div>
          </div>

          <div className="col-sm-6">
            <div
              className="card card-site high p-0"
              style={{ borderRadius: "8px", border: "transparent", backgroundColor: "#FFFFFF", color: "#444444" }}
            >
              <div className="item">
                <img src={props.boxImgTwo} loading="lazy" decoding="async" alt={props.boxAltTwo} />
              </div>
              <div className="p-3">
                <h3 className="cardHeader-site pb-2" style={{ fontSize: "20px" }}>
                  <strong>{props.boxTitleTwo}</strong> <br />
                  <span
                    className="badge badge-secondary m-3"
                    style={{ backgroundColor: `${props.colorPalette.buttonNew}` }}
                  >
                    Series 2 Apartments
                  </span>
                </h3>
                <h4
                  className="cardText-site p-0"
                  style={{ color: "#555555", opacity: "0.7", fontSize: "18px", lineHeight: "20px" }}
                >
                  {props.boxAddressTwo} <br /> {props.boxCityTwo}
                </h4>
                <div className="pb-2 pt-2">
                  <hr
                    className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl style-line mt-3 mb-3"
                    style={{ borderColor: "rgba(0, 0, 0, 0.2)" }}
                  />
                </div>
                <div className="pb-4">
                  <span style={{ fontSize: "18px" }}>
                    <a href="tel:651-237-3322">{props.boxPhoneTwo}</a>
                  </span>
                </div>
                <a
                  className="btn btn-md"
                  href={props.boxURLTwo}
                  target="_blank"
                  style={{ backgroundColor: `${props.colorPalette.heroTop}`, color: "#fff" }}
                >
                  <span>Explore Apartment</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
/** export */
export default TwoBoxSection
